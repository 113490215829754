import React from 'react';
import ReactDom from "react-dom"
import { Provider } from 'react-redux';
import App from './App';
import "./frontend.css"
import "./responsive.css"
import store from './store';



const divsToUpdate = document.querySelectorAll(".paying-attention-update-me");

divsToUpdate.forEach(function(div){
    const data = JSON.parse(div.querySelector("pre").innerHTML)
    ReactDom.render(
        <Provider store={store}>
            <App {...data} />
        </Provider>
    ,div)
    div.classList.remove(".paying-attention-update-me")
});


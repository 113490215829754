import { updateBranchInfo } from "../store/slices/OrderSlice";
import { next } from "../store/slices/PageSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';

function Branch({data,distance}) {
    
    const dispatch = useDispatch();
    const order = useSelector((state) => state.order)
    
    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        const parsedHours = parseInt(hours, 10);
    
        // Determine if it's AM or PM
        const period = parsedHours >= 12 ? 'PM' : 'AM';
    
        // Convert hours to 12-hour format
        const hours12 = parsedHours % 12 || 12;
    
        // Formatted time in 12-hour format
        const formattedTime = `${hours12}:${minutes} ${period}`;
    
        return formattedTime;
      };

      const nextClick = (branch, distance) => {
        
        if(distance > Number(branch.far_deliver)){
          Swal.fire({
            title: 'Sorry!',
            text: `We cannot deliver too far. Our delivery range is ${branch.far_deliver} Miles.`,
            icon: 'warning',
            confirmButtonText: 'OK'
        });
          return;
        }
        dispatch(updateBranchInfo({branch:branch, distance:distance}))
        dispatch(next());
      };

      const defaultBanner = `${pluginDirectoryURL}src/frontend/assets/images/sweet-center.png`;
      
    return (
        <>
            <div className="box" onClick={() => nextClick(data, distance)}>
            <div className="box-top">
              <img className="box-image" src={data.banner?'https://app.salesfry.com/'+data.banner:defaultBanner} alt={data.name} />
              <div className="title-flex">
                <h3 className="box-title">{data.name}</h3>
                <p className="user-follow-info">
                  Opening Time: {convertTo12HourFormat(data.opening_time)} to {convertTo12HourFormat(data.closing_time)}
                </p>
                <span className="description">
                  Address: {data.address}
                </span>
                <span className="description">Status: 
                  <span
                  className={`badge ml-2  ${
                data.is_open ? 'badge-success' : 'badge-danger'
              }`}
            >
              {data.is_open ? 'Open' : 'Close'}
            </span> 
            </span>
                
                {order.orderType == 'delivery' ? (
                    <span className="description">
                        Distance: {distance} Miles away
                    </span>
                ) : ""}
              </div>
            </div>
          </div>
        </>
    )
}

export default Branch;

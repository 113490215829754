
import { useDispatch, useSelector } from 'react-redux';

import Index from './pages/Index';
import Branches from './pages/Branches';
import Products from './pages/Products';
import Checkout from './pages/Checkout';
import Login from './pages/Login';
import { addGoogleApiKey, addSalesFryApiKey } from './store/slices/AuthSlice';



function App(props) {

  const dispatch = useDispatch();

    const apiKey = props.googleApiKey;
    const salesFryApiKey = props.authKey;

    dispatch(addGoogleApiKey(apiKey));
    dispatch(addSalesFryApiKey(salesFryApiKey));

  const page=useSelector((state)=>state.page.value)
 
  const PageDisplay=()=>{
    switch(page){
      case 0:
        return <Index />
      case 1:
        return <Branches/>
      case 2:
        return <Products/>
      case 3:
        return <Checkout />
      case 4:
        return <Login />
      default:
        return <Index />
    }
  }

  return (
    <div id="sfry">
      {PageDisplay()}
    </div>
  );
}

export default App;

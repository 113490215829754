import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentOrder } from "../store/slices/OrderSlice";
import { back,start,resetBypass } from "../store/slices/PageSlice";


function TopBar(props) {
    
    const dispatch = useDispatch();
    const order = useSelector((state) => state.order);
    const page = useSelector((state) => state.page.value);
    const bypassVal = useSelector((state) => state.page.bypassVal);
    const [orderType, setOrderType] = useState(order.orderType);
    const [dateTime, setDateTime] = useState(order.dateTime);

    useEffect(() => {
        dispatch(
          currentOrder({
            ...order,
            dateTime: dateTime,
            orderType: orderType,
          })
        );
      }, [dateTime,orderType]);

      const handleBackClick = () => {
        if (page === 2 && bypassVal==1) {
            dispatch(resetBypass());
            dispatch(start()); // Dispatch the start action if page is 2
        } else {
            dispatch(back()); // Otherwise, dispatch the back action
        }
    };

    return (
        <>
          
            <div className="row">
          <div className="text-left col-md-6">
          <label>&nbsp;</label>
            <div className="btn-group">
              
              <button type="button" className="btn sfry-green" onClick={handleBackClick}>
                Back
              </button>
            </div>
          </div>

          
            <div className="datetime-container col-md-3">
              <label htmlFor="custom-datetime">Order Date Time</label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => setDateTime(e.target.value)}
                id="custom-datetime"
                value={dateTime}
              />
            </div>
            
            <div className="form-group col-md-3">
            <label htmlFor="custom-datetime">Order Type</label>
            <select
              className="form-control"
              value={orderType}
              onChange={(e) => setOrderType(e.target.value)}
            >
              <option
                value="delivery"
              >
                Delivery
              </option>
              <option
                value="collection"
              >
                Collection
              </option>
            </select>
           
            </div>
          
          </div>
        
        </>
    )
}

export default TopBar;

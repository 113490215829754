import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

// Function to calculate total price of modifiers
function calculateModifiersTotal(modifiers) {
  if (!modifiers || modifiers.length === 0) {
    return 0;
  }
  return modifiers.reduce((total, modifier) => total + parseFloat(modifier.price), 0);
}

// Function to calculate the total
function calculateTotal(subtotal, deliveryCharges) {
  console.log(subtotal)
  return subtotal + parseFloat(deliveryCharges);
}

const orderSlice = createSlice({
  name: "order",
  initialState: {
    branchInfo: "",
    orderType: "",
    address: "",
    dateTime: "",
    userLat: "",
    userLng: "",
    branchID: "",
    distance: "",
    branchLat: "",
    branchLng: "",
    businessCurrency: "",
    nextPermission: false,
    nextClick: false,
    addressValid: true,
    plan: "",
    price: 0,
    plantime: "",
    subtotal: 0,
    branchDeliveryCharges: 0,
    total: 0,
    products: [],
    paymentMethod: "Cash on Delivery",
    tip: null,
    instruction: "",
    name: "",
    email: "",
    phoneNumber: "",
  },
  reducers: {
    currentOrder: (state, action) => {
      state.orderType = action.payload.orderType;
      state.dateTime = action.payload.dateTime;
      state.address = action.payload.address;
      state.userLat = action.payload.userLat;
      state.userLng = action.payload.userLng;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
    },

    updateBranchInfo: (state, action) => {
      
      const distance = action.payload.distance;
      const isDistanceInteger = Number.isInteger(distance);

      state.branchDeliveryCharges = (isDistanceInteger)? action.payload.branch.delivery_charges*(distance-parseFloat(action.payload.branch.free_delivery_range)): 0;
      state.branchInfo = action.payload.branch;
      state.branchID = action.payload.branch.id;
      state.distance = action.payload.distance;
      state.businessCurrency = action.payload.branch.business.currency.currency_symbol;
      state.total = calculateTotal(state.subtotal, state.branchDeliveryCharges);
    },

    addToCart: (state, action) => {
      const { id, name, type, quantity, gross_price, net_price, vat_name, vat_percent, vat_value, is_modifier, modifiers } = action.payload;

      const existingProductIndex = state.products.findIndex((product) => product.id === id);

      if (existingProductIndex !== -1) {
        state.products[existingProductIndex].quantity += quantity;
      } else {
        state.products.push({
          id,
          name,
          type,
          quantity: 1,
          gross_price,
          net_price,
          vat_name,
          vat_percent,
          vat_value,
          is_modifier,
          modifiers: modifiers,
          lineTotal: 0,
        });
      }

      const product = state.products.find((product) => product.id === id);
      const modifiersTotal = calculateModifiersTotal(modifiers);
      const lineTotal = product.gross_price * quantity + (modifiersTotal * quantity);
      product.lineTotal = lineTotal;

      state.subtotal = state.products.reduce((acc, product) => acc + (product.lineTotal || 0), 0);
      state.total = calculateTotal(state.subtotal, state.branchDeliveryCharges);
    },

    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      const product = state.products.find((p) => p.id === productId);

      if (product) {
        const modifiersTotal = calculateModifiersTotal(product.modifiers);
        const lineTotal = product.gross_price * quantity + (modifiersTotal * quantity);

        product.quantity = quantity;
        product.lineTotal = lineTotal;

        state.subtotal = state.products.reduce((acc, p) => acc + (p.lineTotal || 0), 0);
        state.total = calculateTotal(state.subtotal, state.branchDeliveryCharges);
      }
    },

    removeProduct: (state, action) => {
      const productId = action.payload;
      state.products = state.products.filter((product) => product.id !== productId);

      state.subtotal = state.products.reduce((acc, product) => acc + (product.lineTotal || 0), 0);
      state.total = calculateTotal(state.subtotal, state.branchDeliveryCharges);
    },

    setPaymentMethod: (state, action) => { // New reducer to update payment method
      state.paymentMethod = action.payload;
    },

    updateTip: (state, action) => {
      state.tip = action.payload;
    },

    updateInstruction: (state, action) => {
      state.instruction = action.payload;
    },

  },
});

export const { currentOrder, updateBranchInfo, addToCart, updateQuantity, removeProduct, setPaymentMethod, updateTip, updateInstruction } = orderSlice.actions;
export default orderSlice.reducer;

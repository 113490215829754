import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBranchInfo } from "../store/slices/OrderSlice";
import { next,setBypass } from "../store/slices/PageSlice";
import TopBar from "./TopBar";
import axios from "axios";
import Branch from "./Branch";

function Branches() {
    
    
    const authKeys = useSelector((state) => state.authKeys);
    const order = useSelector((state) => state.order);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [distances, setDistances] = useState({}); // Store distances for each branch
    const dispatch = useDispatch();

    useEffect(() => {
        
        // Fetch branches data from the API
        const fetchBranches = async () => {
            try {
                const response = await axios.get('https://app.salesfry.com/api/business-active-branches', {
                    headers: {
                        'API-Key': authKeys.salesFryApiKey,
                        'lat': order.userLat,
                        'lng': order.userLng
                    }
                });
                
                

                // If there's only one branch, calculate the distance and redirect
                // if (response.data.length === 1) {
                //     const branch = response.data[0];
                //     const distance = await calculateDistance(
                //         parseFloat(branch.latitude),
                //         parseFloat(branch.longitude),
                //         branch.id
                //     );
                //     dispatch(updateBranchInfo({ branch, distance }));
                //     dispatch(setBypass());
                //     dispatch(next());
                    
                // }
                setLoading(false);
                setBranches(response.data);
                

            } catch (error) {
                const errorMessage = error.response ? error.response.data.message : 'Network Error';
                alert(`${errorMessage}. Contact SuperAdmin`);
                console.error('Error fetching branches:', error);
                setLoading(false);
            }
        };

        fetchBranches();
    }, []);

    // Function to calculate distance for a specific branch
    const calculateDistance = async (branchLatitude, branchLongitude, branchId) => {
        try {
            const response = await axios.post('https://app.salesfry.com/api/calculate-distance-for-plugin', {
                originLatitude: branchLatitude,
                originLongitude: branchLongitude,
                destinationLatitude: order.userLat,
                destinationLongitude: order.userLng,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'API-Key': authKeys.salesFryApiKey
                }
            });
            // Update distances state with the calculated distance for the branch
            setDistances(prevDistances => ({
                ...prevDistances,
                [branchId]: response.data
            }));
            
        } catch (error) {
            alert(error + 'Error calculating distance. Contact SuperAdmin')
            
            console.error('Error calculating distance:', error);
        }
    };

    useEffect(() => {
        // Calculate distances for each branch when branches or order state changes
        branches.forEach(branch => {
            calculateDistance(parseFloat(branch.latitude), parseFloat(branch.longitude), branch.id);
        });
    }, [branches]);

    return (
        <section id="branches-page">
            <TopBar />
            {loading && (
                <div className="row">
                    <div className="text-center col-md-12 mt-5">
                <div className="spinner-border text-success" style={{ fontSize: '1.5rem' }} role="status">
                    <span className="visually-hidden"></span>
                </div>
                </div>
                </div>
            )}
            {!loading && branches && (
                <>
                    <div className="text-center mt-3 mb-3">
                        <h2 className="restaurant-title">
                            The List of Restaurants that deliver to your address
                        </h2>
                    </div>
                    <div className="swrap">
                        {branches.map((branch) => (
                            <Branch data={branch} key={branch.id} distance={distances[branch.id]} />
                        ))}
                    </div>
                </>
            )}
        </section>
    );
}

export default Branches;
import { React, useEffect, useState } from "react";
import { Form, Modal, Button, Card, ListGroup, Badge } from 'react-bootstrap';
import TopBar from "./TopBar";
import axios from "axios";
import CategoriesBar from "./CategoriesBar";
import SubcategoriesBar from "./SubcategoriesBar";
import Product from "./Product";
import { currentOrder, addToCart, updateQuantity, removeProduct } from "../store/slices/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../store/slices/PageSlice";
import VariantModal from "./VariantModal";
import Modifier from "./Modifier";


function Products(props) {
    const dispatch = useDispatch();
    const authKeys = useSelector((state) => state.authKeys);
    const order = useSelector((state) => state.order);
    const subtotal = useSelector((state) => state.order.subtotal);
    const [showVariants, setShowVariants] = useState(false);
    const [showModifiers, setShowModifiers] = useState(false);
    const [products, setProducts] = useState([]);
    const [variantProduct, setVariantProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubcategory, setSelectedSubcategory] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(0);

    useEffect(() => {
        
        // URL of the API endpoint
        const apiUrl = 'https://app.salesfry.com/api/categories-with-subcategories-for-plugin';
    
        // Data to be sent in the headers
        const headersData = {
          'API-Key': authKeys.salesFryApiKey
        };

        const postData = {
            branch_id: order.branchID,
          };
    
        // Options object for the Axios request
        const requestOptions = {
          method: 'post', // GET request
          url: apiUrl,
          headers: {
            'Content-Type': 'application/json', // Set the content type based on your API requirements
            ...headersData, // Include additional headers
          },
          data: postData,
        };
    
        // Make the Axios GET request
        axios(requestOptions)
          .then((response) => {
            console.log(response.data)
            // Handle the successful response data
            setProducts(response.data);
            setLoading(false);
            // setError(null);
          })
          .catch((error) => {
            alert(error + 'Contact SuperAdmin')
            console.log(error)
            // Handle errors
            // setBranches(null);
            // setLoading(false);
            // setError(error);
          });
      }, []);

      const handleCategoryChange = (index) => {
        setSelectedCategory(index);
        setSelectedSubcategory(0); // Reset subcategory when category changes
      };
    
      const handleSubcategoryChange = (index) => {
        setSelectedSubcategory(index);
      };

      
    const selectedProducts = (products.length > 0)? products[selectedCategory].subcategories[selectedSubcategory].products:[];
      

      const pushToCart = (product) =>{
       
        if(product.is_variants){  // the product has variants
          setShowVariants(true);
          setVariantProduct(product);
        }
        else{ // The product is non variant 

          if(product.is_modifier){ // check if the product has modifiers
              setShowModifiers(true);
              setSelectedProduct(product)
          }else{

            const newProduct = {
              id: product.id,
              name: product.product_name,
              type: product.type,
              quantity: 1,
              gross_price: product.gross_price,
              net_price: product.net_price,
              vat_name: product.vat_name,
              vat_percent: product.vat_percent,
              vat_value: product.vat_value,
              is_modifier: product.is_modifier,
            };
  
            dispatch(addToCart(newProduct));

          }
          
          
        }

          

      };

      const handleQuantityChange = (productId, newQuantity) => {
        // Dispatch the action to update the quantity
        dispatch(updateQuantity({ productId, quantity: newQuantity }));
      };

      const handleIncrement = (productId) => {
        // Increment the quantity and dispatch the action
        const product = order.products.find((p) => p.id === productId);
        if (product) {
          handleQuantityChange(productId, product.quantity + 1);
        }
      };
    
      const handleDecrement = (productId) => {
        // Decrement the quantity and dispatch the action
        const product = order.products.find((p) => p.id === productId);
        if (product && product.quantity > 1) {
          handleQuantityChange(productId, product.quantity - 1);
        }
      };

      const [searchTerm, setSearchTerm] = useState('');
const [filteredProducts, setFilteredProducts] = useState([]);

// Function to handle search input changes
const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
};

useEffect(() => {
  // Ensure that the filter function is not recreated on every render
  const debouncedFilter = setTimeout(() => {
    // Filter products based on search term
    const newFilteredProducts = selectedProducts.filter((product) =>
      product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Update the state with filtered products
    setFilteredProducts(newFilteredProducts);
  }, 300);

  return () => clearTimeout(debouncedFilter);
}, [searchTerm, selectedProducts]);

    return (
      <section id="products-page">
        <div className="container-fluid">
            <TopBar />

        <div className="row">
            <div className="col-12">
                <div id="category-container">
                    <CategoriesBar categories={products} selectedCategoryIndex={selectedCategory} onCategoryChange={handleCategoryChange}  />
                </div>
                <div className="search-container col-12 text-center">
                    <input type="text" id="product-search" value={searchTerm} onChange={handleSearchChange} placeholder="Search for products..." />
                </div>
            </div>
            
        </div>
        
        <div id="product-list">
            
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    {/* <div className="subCatgroyBar">
                    <div className="appetizers subactList" data-category="appetizers">
                           {(!products || products.length === 0)?"No subcategories": <SubcategoriesBar subcategories={products[selectedCategory].subcategories} selectedSubCategoryIndex={selectedSubcategory}
                            onSubcategoryChange={handleSubcategoryChange} />}
                    </div>
                    </div> */}


                    {(!products || products.length === 0)?"No subcategories": products[selectedCategory].subcategories.map((subcategory, index) => (

                    <div  key={index} className="text-center">
                    <h2>{subcategory.subcategory}</h2>

                    <div className='row productList'>
                      
                    {(!products || subcategory.products.length === 0)?(<div className="col-12 alert alert-warning">No products</div>): filteredProducts.map((product, productIndex) => (
                       
                        <Product key={productIndex} product={product} onDataClick={() => pushToCart(product)}  />
                        
                      ))}     
                    </div>

                    </div>
                    ))}
                          
                    </div>

                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12">

                        <div className="cart-container">
                          <h3 className="text-center border-bottom">Your Cart</h3>

                            {
                                order.products.map((product) => (
                                  <div style={{borderBottom:'1px solid #ddd'}} key={product.id}>
                                    <div className="product">
                                      <i className="fa fa-trash text-danger trushIcon" onClick={() => dispatch(removeProduct(product.id))}></i>
                                        <div className="productName">{product.name} 
                                   
                                        
                                        </div>
                                        <div className="qty-box">
                                            <i className="fas fa-minus" onClick={() => handleDecrement(product.id)}></i>
                                            <input type="text" className="quantity" value={product.quantity} min="1" onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value, 10))} />
                                            <i className="fas fa-plus" onClick={() => handleIncrement(product.id)}></i>
                                        </div>
                                        <span className="cartPrice">{parseFloat(product.gross_price).toFixed(2)}</span>
                                        <span className="cartPrice">{parseFloat(product.lineTotal).toFixed(2)}</span>
                                        
                                    </div>
                                    {product.modifiers && 
                                          <ul className="modifier-list">
                                          {product.modifiers.map((modifier, index) => (
                                            <li className="outerModiferName" key={index}>
                                              <div style={{ width: '20px', height: '2px', backgroundColor: 'green' }}></div>
                                              <span>{modifier.item_name} +{modifier.price}</span>
                                            </li>
                                          ))}
                                        </ul>
                                        
                                        }
                                    </div>
                                    
                                ))
                            }

                            
                    
                        
                            <div className="total cartTotal mt-3">
                                <span >Total:</span>
                                <span className="mr-3" style={{fontSize: '20px'}}>{order.businessCurrency} {parseFloat(subtotal).toFixed(2)}</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="checkout-btn" disabled={order.products.length === 0} onClick={() => dispatch(next())}>Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            
           
        </div>
        
        {(variantProduct != null) && <VariantModal show={showVariants} closeVariantsModal={() => setShowVariants(false)} product={variantProduct} />}
        
        {(selectedProduct.is_modifier) && 
          <Modifier selectedProduct={selectedProduct} showModifiers={showModifiers} setShowModifiers={setShowModifiers} />
        }
          

        </div>
        </section>
    )
}

export default Products;

import React from 'react';

function CategoriesBar({ categories, selectedCategoryIndex, onCategoryChange }) {
    

    return (
        <div id="category-bar">
            {
                categories.map((category, index) => (
                    <div key={index} onClick={() => onCategoryChange(index)} className={`category ${selectedCategoryIndex === index ? 'selected' : ''}`}>{category.category}</div>
                ))
            }
                        
       </div>
    )
}

export default CategoriesBar;

import React from 'react';

function SubcategoriesBar({ subcategories, selectedSubCategoryIndex, onSubcategoryChange }) {
    
    

    return (
        <>


            {subcategories.map((subcategory, index) => (

                <div  className={`link topLink ${selectedSubCategoryIndex === index ? 'selected' : ''}`}  key={index} onClick={() => onSubcategoryChange(index)} >
                <span>{subcategory.subcategory}</span>
                </div>
            ))}
            
        
        </>
    )
}


export default SubcategoriesBar;
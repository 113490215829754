import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


function Product({ product, onDataClick } ) {

    const businessCurrency = useSelector((state) => state.order.businessCurrency);
    const [lowestPrice, setLowestPrice] = useState(null);
    const [highestPrice, setHighestPrice] = useState(null);

    

  useEffect(() => {
    const calculateMinMaxPrices = () => {
      if (product.variants && product.variants.length > 0) {
        const prices = product.variants.map((variant) => variant.gross_price);
        setLowestPrice(Math.min(...prices));
        setHighestPrice(Math.max(...prices));
      }
    };

    // Call the function when the component mounts or when the product changes
    calculateMinMaxPrices();
  }, [product]);

  const defaultImage = `${pluginDirectoryURL}src/frontend/assets/images/burger.png`;

          return (
           
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 product" onClick={onDataClick}>
                        <div className="product-card" style={{border: '2px solid', borderColor:product.color}}>
                            <div className="card-left">
                                <h5>{product.product_name}</h5>
                                {product.is_variants && lowestPrice !== null && highestPrice !== null ? (
                                    <span>From {businessCurrency} {lowestPrice}</span>
                                ) : (
                                    <span>{businessCurrency} {parseFloat(product.gross_price).toFixed(2)}</span>
                                )}

                                
                                <div className="descr_text">{product.description}</div>
                            </div>
                            <div className="card-right">
                                <img alt={product.product_name} src={product.image?'https://app.salesfry.com/'+product.image:defaultImage} />
                            </div>
                        </div>
                    </div>
                    
                   
          );

}


export default Product;
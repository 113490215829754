import React, { useState } from "react";
import { Form, Modal, Button, Card, ListGroup, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../store/slices/OrderSlice";





function Modifier({selectedProduct, showModifiers, setShowModifiers}) {
    const dispatch = useDispatch();
    const order = useSelector((state) => state.order);
    const [selectedModifiers, setSelectedModifiers] = useState([]);

    const handleCheckboxChange = (modifier) => {
        // Check if the modifier is already in the array
        const isModifierSelected = selectedModifiers.some((selected) => selected.id === modifier.id);
    
        // If it's selected, remove it; otherwise, add it to the array
        if (isModifierSelected) {
          setSelectedModifiers((prevSelected) =>
            prevSelected.filter((selected) => selected.id !== modifier.id)
          );
        } else {
          setSelectedModifiers((prevSelected) => [...prevSelected, modifier]);
        }
      };

      const pushToCart = (product) =>{
       
            const newProduct = {
              id: product.id,
              name: product.product_name,
              type: product.type,
              quantity: 1,
              gross_price: product.gross_price,
              net_price: product.net_price,
              vat_name: product.vat_name,
              vat_percent: product.vat_percent,
              vat_value: product.vat_value,
              is_modifier: product.is_modifier,
              modifiers: selectedModifiers,
            };
  
            dispatch(addToCart(newProduct)); 
            setShowModifiers(false)
            setSelectedModifiers([]);
      };


    return (

    <Modal show={showModifiers} onHide={() => setShowModifiers(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Choose Modifiers for {selectedProduct.product_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
          <div className="row">
            {selectedProduct.product_modifiers.map((modifier, index) => (
              <div className="col-lg-6 col-xl-6 col-md-6 mb-3" key={index}>
                <Card >
                  <Card.Header>{modifier.group_name}</Card.Header>
                  <ListGroup variant="flush">
                    {modifier.modifier_items.map((modifier_item, mod_index) => (
                      <ListGroup.Item key={mod_index}>
                        <Form.Check
                          type='checkbox'
                          id={modifier_item.id}
                          label={<span className="modifierLabel">{modifier_item.item_name} <Badge variant="success">+ {order.businessCurrency} {parseFloat(modifier_item.price).toFixed(2)}</Badge> </span>}
                          onChange={() => handleCheckboxChange(modifier_item)}
                          checked={selectedModifiers.some((selected) => selected.id === modifier_item.id)}
                        />
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              </div>
            ))}
          </div>
          
                      
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pushToCart(selectedProduct)}>
              Add with Modifiers
            </Button>
          </Modal.Footer>

          
        </Modal>

    )
}

export default Modifier;

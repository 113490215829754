import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./slices/AuthSlice";
import PageSlice from "./slices/PageSlice";
import orderSlice from "./slices/OrderSlice";


const store = configureStore({
    reducer: {
        authKeys: AuthSlice,
        page: PageSlice,
        order: orderSlice
    },
});


export default store;
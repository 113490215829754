// VariantModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Product from './Product';
import { currentOrder, addToCart, updateQuantity, removeProduct } from "../store/slices/OrderSlice";
import { useDispatch, useSelector } from "react-redux";

const VariantModal = ({ show, closeVariantsModal, product }) => {
  const dispatch = useDispatch();
  const pushToCart = (selectedProduct) =>{
       
   
      
      const newProduct = {
        id: selectedProduct.id,
        name: selectedProduct.product_name,
        type: selectedProduct.type,
        quantity: 1,
        gross_price: selectedProduct.gross_price,
        net_price: selectedProduct.net_price,
        vat_name: selectedProduct.vat_name,
        vat_percent: selectedProduct.vat_percent,
        vat_value: selectedProduct.vat_value,
        is_modifier: selectedProduct.is_modifier,
        };

        dispatch(addToCart(newProduct));

        };

  return (
    <Modal show={show} onHide={closeVariantsModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{product.product_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row productList'>
        {product.variants.map((variant, index) => (
          <Product key={index} product={variant} onDataClick={() => pushToCart(variant)}  />
        ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeVariantsModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VariantModal;

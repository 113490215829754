import React, { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { currentOrder } from "../store/slices/OrderSlice";
import { next } from "../store/slices/PageSlice";

function Index() {
  
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);
  const authKeys = useSelector((state) => state.authKeys);

  const [orderType, setOrderType] = useState(order.orderType?order.orderType:"delivery");
  const [address, setAddress] = useState(order.address?order.address:"");
  const [userLat, setUserLat] = useState(order.userLat?order.userLat:"");
  const [userLng, setUserLng] = useState(order.userLng?order.userLng:"");
  const [dateTime, setDateTime] = useState(order.dateTime?order.dateTime:getCurrentDateTime());

  useEffect(() => {
    
    dispatch(
      currentOrder({
        ...order,
        orderType: orderType,
        address: address,
        userLat:userLat,
        userLng:userLng,
        dateTime: dateTime,
      })
    );
    
  }, [orderType, address, dateTime]);


  const handlePlaceSelect = (selectedPlace) => {
    
    const selectedAddress = selectedPlace.formatted_address;
    const AddressLat = selectedPlace.geometry.location.lat();
    const AddressLng = selectedPlace.geometry.location.lng();
   
    // Saved address along with latitude and lagitude to calculate distance
    
    setUserLat(AddressLat);
    setUserLng(AddressLng);
    setAddress(selectedAddress);

  };

  const initializeGooglePlaces = () => {
   
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("address-input"),
      {
        types: ["geocode"],
        componentRestrictions: { country: 'uk' }
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      
      handlePlaceSelect(place);
    });
  };

  // Load the Google Maps Places API script
  
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${authKeys.googleApiKey}&libraries=places`;
    script.async = true;
    script.onload = initializeGooglePlaces;
    document.head.appendChild(script);
  };

  // Load the script when the component mounts
  useEffect(() => {
    loadScript();
  }, []);

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const nextClick = () => {
    dispatch(next());
  };

  return (
    <>
      <section id="address-page">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h1 className="mb-5">
                  <span className="text-success">Order</span> food to your door
                </h1>
                <form style={{ textAlign: "left" }}>
                  <MDBTabs className="nav nav-pills mb-3" id="pills-tab">
                    <MDBTabsItem>
                      <MDBTabsLink
                        onClick={() => setOrderType("delivery")}
                        active={orderType === "delivery"}
                      >
                        Delivery
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink
                        onClick={() => setOrderType("collection")}
                        active={orderType === "collection"}
                      >
                        Collection
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBTabs>

                  <MDBTabsContent>
                    <div className="datetime-container">
                      <label htmlFor="custom-datetime">Date Time:</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        onChange={(e) => setDateTime(e.target.value)}
                        id="custom-datetime"
                        value={dateTime}
                      />
                    </div>
                    <MDBTabsPane open={orderType === "delivery"}>
                      <div className="address-container">
                    <label htmlFor="custom-datetime">Address:</label>
                      <div className="input-group mb-2">
                        
                        <div className="input-group-prepend">
                          <div className="input-group-text bg-white">
                            {" "}
                            <i className="fa-solid fa-location-dot"></i>
                          </div>
                        </div>
                        <i className="fa-solid fa-location-exclamation"></i>
                        <input
                          type="text"
                          className="form-control shadow-none"
                          id="address-input"
                          
                          placeholder="Enter your address"
                        />
                      </div>
                      </div>
                    </MDBTabsPane>
                    <button
                      type="button"
                      className="btn sfry-green mt-2"
                      onClick={nextClick}
                    >
                      ORDER FOOD
                    </button>
                  </MDBTabsContent>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Index;

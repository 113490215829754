import { React, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { start, back } from "../store/slices/PageSlice";
import { currentOrder, setPaymentMethod, updateTip, updateInstruction } from "../store/slices/OrderSlice";

function Checkout(props) {
    
    const dispatch = useDispatch();
    const authKeys = useSelector((state) => state.authKeys);
    const order = useSelector((state) => state.order);
    const subtotal = useSelector((state) => state.order.subtotal);
    const total = useSelector((state) => state.order.total);

    const tip = useSelector((state) => state.order.tip);

    const [orderType, setOrderType] = useState(order.orderType);
    const [dateTime, setDateTime] = useState(order.dateTime);
    const [name, setName] = useState(order.name);
    const [email, setEmail] = useState(order.email);
    const [phoneNumber, setPhoneNumber] = useState(order.phoneNumber);
    const [alertVisible, setAlertVisible] = useState(false);

    useEffect(() => {
        dispatch(
          currentOrder({
            ...order,
            dateTime: dateTime,
            orderType: orderType,
            name:name,
            email: email,
            phoneNumber: phoneNumber,
          })
        );
      }, [dateTime,orderType, email, phoneNumber]);

    const handleTipSelection = (tipPercentage) => {
        // Dispatch an action to update the tip value in the order slice
        dispatch(updateTip(tipPercentage));
    };

    // Set the default tip value to null if it's not already set
    useEffect(() => {
        if (tip === null) {
            handleTipSelection(null);
        }
    }, []); // This effect will run only once when the component mounts

    const handlePaymentMethodChange = (event) => {
        dispatch(setPaymentMethod(event.target.value));
    };

    // Detect the user agent to determine the payment method text
    const userAgent = navigator.userAgent;
    let mobilePayText = "";

    if (/iPad|Macintosh/i.test(userAgent)) {
        mobilePayText = "Apple Pay";
    } else if (/Android|Windows/i.test(userAgent)) {
        mobilePayText = "Google Pay";
    }

    const handleInstructionChange = (e) => {
        dispatch(updateInstruction(e.target.value));
      };

      const handlePayNow = async () => {
        try {
          const response = await axios.post('https://app.salesfry.com/api/online-orders', order, {
            headers: {
              'API-Key': authKeys.salesFryApiKey, // Include the API key in the headers
              'Content-Type': 'application/json'
            }
          });
          console.log('Order placed successfully:', response.data);

        setAlertVisible(true); // Show the alert
        setTimeout(() => {
            setAlertVisible(false); // Hide the alert after 30 seconds
            dispatch(start());
        }, 5000);

        } catch (error) {
          console.error('Error placing order:', error);
          // Optionally handle the error, e.g., show an error message
        }
      };

    return (
        <>
            <section id="checkout-page">
        <div className="container-fluid">
        <div className="btn-group">
              
              <button type="button" className="btn sfry-green" onClick={()=>dispatch(back())}>
                Edit Order
              </button>
            </div>
            <div className="row">
                <div className="col-md-7 col-12">
                    <div className="multi_checkout mt-5">
                        <div className="alert alert-light" role="alert">
                            <label>Delivery Address:</label>
                            <div className="d-flex align-items-center">
                                <span><i className="fa-solid fa-location-dot text-dark"></i> </span>&nbsp;&nbsp; &nbsp;
                                <input
                          type="text"
                          className="form-control shadow-none"
                          id="address-input"
                          value={order.address}
                          placeholder="Enter your address"
                        />
                            </div>
                            
                           
                        </div>
                        <div className="alert alert-light" role="alert">
                            <div className="d-flex align-items-center">
                                <span><i className="fa-solid fa-person-walking text-dark"></i> </span>&nbsp;&nbsp; &nbsp;
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    id="instruction"
                                    value={order.instruction}
                                    onChange={handleInstructionChange}
                                    placeholder="Add order instruction"
                                />
                               
                            </div>
                            
                        </div>
                        <div className="alert alert-light border-0 col-md-6" role="alert">
                        <div className="datetime-container">
              {/* <label htmlFor="custom-datetime">Order Date Time</label> */}
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => setDateTime(e.target.value)}
                id="custom-datetime"
                value={dateTime}
              />
            </div>
            
            <div className="form-group mt-2">
            {/* <label htmlFor="custom-datetime">Order Type</label> */}
            <select
              className="form-control"
              value={orderType}
              onChange={(e) => setOrderType(e.target.value)}
            >
              <option
                value="delivery"
              >
                Delivery
              </option>
              <option
                value="collection"
              >
                Collection
              </option>
            </select>
           
            </div>
                        </div>
                        
                        
                        <div className="alert alert-light" role="alert">
                                    <div className="d-flex align-items-center">
                                        <span className="font-weight-bold text-dark">Payment Method</span>
                                    </div>
                                    <div className="form-check">
                                    
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="paymentMethod"
                                            id="cashOnDelivery"
                                            value="Cash on Delivery"
                                            checked={order.paymentMethod === "Cash on Delivery"}
                                            onChange={handlePaymentMethodChange}
                                        />
                                        
                                        <label className="form-check-label" htmlFor="cashOnDelivery">
                                        <i class="fa-solid fa-wallet" style={{marginRight: "7px"}}></i> 
                                            Cash on Delivery
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="paymentMethod"
                                            id="payByCard"
                                            value="Pay by Card"
                                            checked={order.paymentMethod === "Pay by Card"}
                                            onChange={handlePaymentMethodChange}
                                        />
                                        
                                        <label className="form-check-label" htmlFor="payByCard">
                                        <i class="fa-regular fa-credit-card" style={{marginRight: "7px"}}></i> 
                                            Pay by Card
                                        </label>
                                    </div>
                                    {mobilePayText && (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="paymentMethod"
                                                id="mobilePay"
                                                value={mobilePayText}
                                                checked={order.paymentMethod === mobilePayText}
                                                onChange={handlePaymentMethodChange}
                                            />
                                            
                                            <label className="form-check-label" htmlFor="mobilePay">
                                            {mobilePayText === "Google Pay" ? (
                                                <i class="fa-brands fa-google" style={{marginRight: "7px"}}></i>
                                                ) : (
                                                <i class="fa-brands fa-apple" style={{marginRight: "7px"}}></i>
                                                )}

                                                {mobilePayText}
                                            </label>
                                        </div>
                                    )}
                                </div>
                            
                        <div className="alert alert-light" role="alert">
                            <div className="d-flex align-items-center">
                                <span><i className="fa-brands fa-adversal text-dark"></i> </span>&nbsp;&nbsp; &nbsp;
                                <span>Promotion not available on alcohal or regulated items.</span>
                            </div>
                        </div>
                        <div className="alert alert-light border-0 mb-0 pb-0" role="alert">
                            <div className="d-flex align-items-center">
                                <span className="font-weight-bold text-dark">Your Items</span>
                            </div>
                            
                        </div>
                        {
                                order.products.map((product) => (
                        <div className="alert alert-light" role="alert">
                            <div className="d-flex justify-content-between">
                                
                                    <button className="btn btn-light" type="button">
                                    {product.quantity}
                                    </button>
                                    
                            
                                <span className="font-weight-bold text-dark">{product.name}</span>
                                <span className="cartPrice">{order.businessCurrency} {parseFloat(product.gross_price).toFixed(2)}</span>
                                <span
                                        className="text-dark">{order.businessCurrency} {parseFloat(product.lineTotal).toFixed(2)}</span> 
                            </div>
                            {/* <img className="img-fluid" src="./assets/images/icecream.png" alt="image" style={{minWidth: '100px'}} /> */}
                            {product.modifiers && 
                                          <ul className="modifier-list">
                                          {product.modifiers.map((modifier, index) => (
                                            <li className="outerModiferName" key={index}>
                                              <div style={{ width: '20px', height: '2px', backgroundColor: 'green' }}></div>
                                              <span>{modifier.item_name} +{modifier.price}</span>
                                            </li>
                                          ))}
                                        </ul>
                                        
                                        }
                        </div>
                         ))
                        }
                       
                    </div>
                </div>
                <div className="col-md-5 col-12 gray-class">
                    <div className="check_place_order">
                        <div className="container">
                            
                            
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Subtotal</th>
                                        <th className="text-right" scope="col">{order.businessCurrency} {parseFloat(subtotal).toFixed(2)}</th>
                                    </tr>
                                    
                                    <tr>
                                        <th scope="col">Delivery Charges</th>
                                        <th className="text-right" scope="col">{order.businessCurrency} {parseFloat(order.branchDeliveryCharges).toFixed(2)}</th>
                                    </tr>
                                </tbody>
                               
                            </table>
                            <p>Add a tip for your courier.</p>
                            <div className="btn-toolbar border-bottom pb-2 justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                <div className="btn-group" role="group" aria-label="Tip Percentage">
                                    <button type="button" className={`btn btn-light bg-white ${tip === null ? "active-button" : ""}`} onClick={() => handleTipSelection(null)}>Not now</button>
                                </div>
                                <div className="btn-group" role="group" aria-label="Tip Percentage">
                                    <button type="button" className={`btn btn-light bg-white ${tip === 0.1 ? "active-button" : ""}`} onClick={() => handleTipSelection(0.1)}>10%</button>
                                </div>
                                <div className="btn-group" role="group" aria-label="Tip Percentage">
                                    <button type="button" className={`btn btn-light bg-white ${tip === 0.15 ? "active-button" : ""}`} onClick={() => handleTipSelection(0.15)}>15%</button>
                                </div>
                                <div className="btn-group" role="group" aria-label="Tip Percentage">
                                    <button type="button" className={`btn btn-light bg-white ${tip === 0.2 ? "active-button" : ""}`} onClick={() => handleTipSelection(0.2)}>20%</button>
                                </div>
                                <div className="btn-group" role="group" aria-label="Tip Percentage">
                                    <button type="button" className={`btn btn-light bg-white ${tip === 0.25 ? "active-button" : ""}`} onClick={() => handleTipSelection(0.25)}>25%</button>
                                </div>
                                <div className="btn-group" role="group" aria-label="Tip Percentage">
                                    <button type="button" className={`btn btn-light bg-white ${tip === "other" ? "active-button" : ""}`} onClick={() => handleTipSelection("other")}>Other</button>
                                </div>
                            </div>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Total</th>
                                        <th className="text-right" scope="col">{order.businessCurrency} {parseFloat(total).toFixed(2)}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                            </div>
                            <div className="form-group">
                            <label>Phone Number:</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                            />
                            </div>
                            <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            </div>

                            <button type="button" className="btn btn-block btn-lg btn-success" disabled={order.products.length === 0} onClick={handlePayNow}>Pay Now</button>

                            {alertVisible && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            Order successfully created, we will update you soon.
                                        </div>
                                    )}

                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </section>
        </>
    )
}

export default Checkout;


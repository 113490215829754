import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
    name: 'auth',
    initialState: {},
    reducers: {
        addGoogleApiKey(state, action){
            state.googleApiKey = action.payload
        },
        removeGoogleApiKey(state, action){
            state.googleApiKey = null
        },
        addSalesFryApiKey(state, action){
            state.salesFryApiKey = action.payload
        },
        removeSalesFryApiKey(state, action){
            state.salesFryApiKey = null
        }
    }
});

console.log(AuthSlice)


export default AuthSlice.reducer
export const {addGoogleApiKey, addSalesFryApiKey} = AuthSlice.actions;